const domitem = document.getElementsByClassName('grid-item-details') 
const domBtnOpen = document.getElementsByClassName('btnOpen')
const domBtnClose = document.getElementsByClassName('btnClose')

const domTost = document.getElementsByClassName('grid-item-tost') 

for (let i = 0; i < domTost.length; i++) {
  domTost[i].style.display = 'none'
}

domBtnOpen.forEach(btnOpen => {
  btnOpen.addEventListener('click', (e) => {
    const key = parseInt(e.target.dataset.key)
    domitem[key].style.display = 'none'
    domTost[key].style.display = 'flex'
  })
})
domBtnClose.forEach(btnOpen => {
  btnOpen.addEventListener('click', (e) => {
    const key = parseInt(e.target.dataset.key)
    domitem[key].style.display = ''
    domTost[key].style.display = 'none'
  })
})

