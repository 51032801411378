//
// register.js
// Theme module
//

let APP_API = '//logate.shengyi.ai';

let form = {
  storename: '', // 店铺民称
  realname: '',
  account: '',
  password: '',
  recommend: '', // 推荐人
  captcha: '', //图片验证码
  vercode: '', //手机验证码
};
let token;
let isturn = true;


let host = window.location.pathname;
if (host == '/register.html') {
  GetCapcode();
}


function onSubmit () {
  var agc = ''
  var source = ''
  var accountName = form.account;
  var accountPwd = form.password;
  var storeName = form.storename;
  var userName = form.realname;
  var recommendCode = form.recommend;
  var vercode = form.vercode;

  try {
    var patternPhone = /^1[3458769]\d{9}$/;
    if (!patternPhone.test(accountName)) {
        ShowErrMsg("accountName", "请输入正确的手机号码！");
    }
    if (!vercode) {
        ShowErrMsg("verifyCode", "请输入正确的验证码！");
    }
    if (!storeName) {
        ShowErrMsg('storeName', '请输入店铺名称！');
    }
    if (!accountPwd) {
        ShowErrMsg('accountPwd', '请输入密码！');
    }
    var url = window.location.href;
    var querys = url.substring(url.indexOf('?') + 1).split('&');
    var result=[];
    for(var i=0;i<querys.length;i++){
        var temp=querys[i].split('=');
        if(temp.length<2){
            result[temp[0]]='';
        }else{
            result[temp[0]]=temp[1];
        }
    }

    var params = {
      account: accountName,
      password: accountPwd,
      storename: storeName,
      rec: recommendCode,
      agc: agc,
      vercode: vercode,
      realname: userName,
      source: source
    };

    const bodyJson = JSON.stringify(params);

    let xhr = new XMLHttpRequest();
    xhr.open('post', `${APP_API}/gatentry/apipub/reg/unireg` );
    xhr.setRequestHeader("content-type","application/json;charset=utf-8");
    xhr.setRequestHeader("token",token);
    xhr.send(bodyJson);
    xhr.onreadystatechange = function () {
        // 这步为判断服务器是否正确响应
      if (xhr.readyState == 4 && xhr.status == 200) {

        let resp = JSON.parse(xhr.response);
        if (resp.code === 200 && resp.data.stat === '0000') {
          const newUrl = 'http://app.shengyi.ai/LoginReg/Login.aspx'
          alert('注册成功');
          window.location.replace(newUrl);
        } else {
          alert(resp.data.desc);
        }
      } 
    };
  } catch (err) {
    alert(err);
  }
}

// 店铺名称
let Storename=document.getElementById("storename");
if (host == '/register.html') Storename.onchange = function getStorename() {
  form.storename = Storename.value;
}
// 店主姓名
let Realname=document.getElementById("realname");
if (host == '/register.html') Realname.onchange = function getRealname() {
  form.realname = Realname.value;
}
// 手机号码
let Account=document.getElementById("account");
if (host == '/register.html') Account.onchange = function getAccount() {
  form.account = Account.value;
}
// 密码
let Password=document.getElementById("password");
if (host == '/register.html') Password.onchange = function getPassword() {
  form.password = Password.value;
}
// 推荐人
let Recommend=document.getElementById("recommend");
if (host == '/register.html') Recommend.onchange = function getRecommend() {
  form.recommend = Recommend.value;
}
// 图片验证码
let Captcha=document.getElementById("captcha");
if (host == '/register.html') Captcha.onchange = function getCaptcha() {
  form.captcha = Captcha.value;
}
// 手机验证码
let Vercode=document.getElementById("vercode");
if (host == '/register.html') Vercode.onchange = function getVercode() {
  form.vercode = Vercode.value;
}

// 获取验证码
let codeImg = document.getElementById('codeSrc');
if (host == '/register.html') codeImg.addEventListener('click', GetCapcode);

// 获取手机验证码
let phoneCodebtn = document.getElementById('phoneCodebtn');
if (host == '/register.html') phoneCodebtn.addEventListener('click', getSmsCode)

// 注册
let submit = document.getElementById('register');
if (host == '/register.html') submit.addEventListener('click', onSubmit);

async function GetCapcode () {
  await capcode()
}

function capcode (params) {
  let xhr = new XMLHttpRequest();
  xhr.open('get',`${APP_API}/gatentry/apipub/reg/capcode`);
  xhr.send();
  xhr.onreadystatechange = function () {
    if (xhr.readyState == 4 && xhr.status == 200) {
      let resp = JSON.parse(xhr.response);
      if (resp.code == 200) {
        codeImg.src = 'data:image/jpg;base64,' + resp.data.image
        token = resp.data.token
      }
    }
  }
}

// 手机验证码
function getSmsCode () {
  if (!isturn) {
    return;
  }

  var accountName = form.account;
  var verifyCode = form.captcha;

  // const regPhone = /^1(3\d|4[5-9]|5[0-35-9]|6[2567]|7[0-8]|8\d|9[0-35-9])\d{8}$/
  const regPhone = /^1[3458769]\d{9}$/;
  if (!regPhone.test(accountName)) {
    alert('请输入正确手机号');
    return;
  }
  if (!verifyCode) {
    alert('请输入正确的验证码！');
    return;
  }

  getSmsCapReq(form.captcha, form.account, token);
}

function getSmsCapReq (captcha, account, token) {
  const params = {
    captcha: captcha,
    phone: account,
    token: token
  }

  const bodyJson = JSON.stringify(params);

  let xhr = new XMLHttpRequest();
  xhr.open('post', `${APP_API}/gatentry/apipub/reg/smscode` );
  xhr.setRequestHeader("content-type","application/json;charset=utf-8");
  xhr.setRequestHeader("token",token);
  xhr.send(bodyJson);
  xhr.onreadystatechange = function () {
      // 这步为判断服务器是否正确响应
    if (xhr.readyState == 4 && xhr.status == 200) {
      let resp = JSON.parse(xhr.response);

      alert(resp.data.desc);
      if (resp.code === 200 && resp.data.stat === '0000') {
        setCapTTL(phoneCodebtn);
        return;
      }
    } 
  };
}

function setCapTTL(smsCapBtn) {
  isturn = false;
  var count = 30;
  var countdown = setInterval(CountDown, 1000);

  smsCapBtn.setAttribute("disabled", 'disabled');
  function CountDown() {
    smsCapBtn.innerHTML = "" + count + " 秒再试";
    if (count === 0) {
      smsCapBtn.innerHTML = "获取验证码";
      smsCapBtn.removeAttribute("disabled");
      clearInterval(countdown);
      isturn = true;
    }
    count--;
  }
}


function ShowErrMsg(id, message) {
  throw message;
}

