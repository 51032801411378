((global)=>{
  
  let PlayerInstance={
    ...play({vid : '' }),
    ...pause(),
  }
  var videoLinks = []
  let videoModal =  document.getElementById('videoModal');
  
  var player =  null
  videoLinks = Array.from(document.querySelectorAll('[data-vid]'));

  videoLinks.forEach(el => {
    el.addEventListener('click', () => {
        showPlayer();
        if (el.dataset.vid) {
          play(el.dataset.vid);
        }
    });

    videoModal.querySelector('.video-mask').addEventListener('click', () => {
      pause();
      hidePlayer();
    });
  });

  function showPlayer() {
    videoModal.className = `${videoModal.className} is-show`;
  }

  function hidePlayer() {
    videoModal.className = `video-modal`;
  }

  function play(vid) {
    if (!player) {
      player = new global.Txplayer({
        containerId: 'videoContainer',
        width: '100%',
        height: '100%',
        autoplay: true,
        vid,
      });
      return;
    }
    
  }
  function pause() {
    if (!player) {
      return
    }
    player = null
  }
  
  function getInstance() {
    if (!BossSayPage.instance) {
        BossSayPage.instance = new BossSayPage();
    }
    return BossSayPage.instance;
  }
  global.addEventListener('load', () => {
    const page = BossSayPage.getInstance();
    console.log(page)
    global.page = page;
});
})(window)
