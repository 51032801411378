//
// navbar.js
// Theme module
//

const navbarTogglable = document.querySelectorAll('.navbar-togglable');
const navbarCollapse = document.querySelectorAll('.navbar-collapse');
const windowEvents = ['load', 'scroll'];

let isLight = false;

function makeNavbarDark(navbar) {
  navbar.classList.remove('navbar-light');
  navbar.classList.remove('bg-white');
  navbar.classList.add('navbar-dark');

  isLight = false;
}

function makeNavbarLight(navbar) {
  navbar.classList.remove('navbar-dark');
  navbar.classList.add('navbar-light');
  navbar.classList.add('bg-white');

  isLight = true;
}

function toggleNavbar(navbar) {
  const scrollTop = window.pageYOffset;

  if (scrollTop && !isLight) {
    makeNavbarLight(navbar);
  }

  if (!scrollTop) {
    makeNavbarDark(navbar);
  }
}

function overflowHide() {
  const scrollbarWidth = getScrollbarWidth();

  document.documentElement.style.overflow = 'hidden';
  document.body.style.paddingRight = scrollbarWidth + 'px';
}

function overflowShow() {
  document.documentElement.style.overflow = '';
  document.body.style.paddingRight = '';
}

function getScrollbarWidth() {
  return window.innerWidth - document.documentElement.clientWidth;
}

navbarTogglable.forEach(function(navbar) {
  windowEvents.forEach(function(event) {
    window.addEventListener(event, function() {
      toggleNavbar(navbar);
    });
  });
});

navbarCollapse.forEach(function(collapse) {
  collapse.addEventListener('show.bs.collapse', function() {
    overflowHide();
  });

  collapse.addEventListener('hidden.bs.collapse', function() {
    overflowShow();
  });
});

(()=>{
  const host = window.location.href
  // const dom = document.getElementById('PopupDilog')
  // const Coundom = document.getElementById('Countdown')
  var num = 5
  if(host.indexOf('www.i200.cn') !== -1){
    window.location.href = '//www.shengyi.ai'

    // dom.style.display='block'
    // Coundom.innerHTML = num + '秒后自动跳转至新网站...'
    // setTimeout(() => {
    //   Countdown()
    // }, 1000);
  }
  var intervalID
  function Countdown() {    
    num = num - 1
    if(num > 0 ){
      // intervalID = setInterval(function(){ Countdown() }, 1000);
      // Coundom.innerHTML = num + '秒后自动跳转至新网站...'
      intervalID
    } else {
      clearInterval(intervalID)
      window.location.href = '//www.shengyi.ai'
    }
  }
})(window)